import $ from 'jquery';

import factory from 'utils/factory';

import Module from 'modules/Module';
// Enable inline svgs in IE
// import svg4everybody from 'svg4everybody';

// svg4everybody();

$(document).ready(() => {
  factory(Module, '.js-module');
});
require('popper.js');
require('bootstrap');
// Enable inline svgs in IE
// import svg4everybody from 'svg4everybody';

// svg4everybody();

$(document).ready(() => {
  factory(Module, '.js-module');
  var menuButton = $('.navbar-toggler');
  var menu = $('#MainNav');

  menuButton.unbind('click');
  menuButton.click(function() {
    // console.log(this);
    menu.slideToggle(200);
  });

  if ($('.page--ProjectsHolder') || $('.page--NewsHolder')) {
    if (window.location.hash) {
      var hash = window.location.hash.substring(1);
      console.log('x', hash);
      $('div.section__popup').hide();
      $('div.sessions--container > .row').hide();

      $('#' + hash).show();
      $(window).trigger('resize');
      $('.flexslider').resize();
    }
  }
  var popButton = $('.open--near-popup');
  popButton.unbind('click');
  popButton.click(function(e) {
    if ($('.page--ProjectsHolder') || $('.page--NewsHolder')) {
      // e.preventDefault();
    }
    // console.log(this);
    var ref = $(this).data('ref');
    $('div.section__popup').hide();
    $('div.sessions--container > .row').hide();

    $('#' + ref).show();
    $(window).trigger('resize');
    $('.flexslider').resize();
    // $(this).parent().parent().parent().parent().parent().find('div.section__popup').show();
  });

  var xclosePopButton = $('.section__popup .close');
  xclosePopButton.unbind('click');
  xclosePopButton.click(function() {
    // console.log(this);
    $('div.section__popup').hide();
    $('div.sessions--container > .row').show();
  });

  /*
  var colCount = 4;
  var colHeights = [];
  var container = document.getElementById('block-contain');
  for (var i = 0; i <= colCount; i++) {
    colHeights.push(0);
  }
  for (var ii = 0; ii < container.children.length; ii++) {
    var order = (ii + 1) % colCount || colCount;
    container.children[ii].style.order = order;
    colHeights[order] += parseFloat(container.children[ii].offsetHeight);
  }

  var highest = Math.max.apply(Math, colHeights);
  container.style.height = highest + 'px';
  */
});
